<template>
  <div class="bg-gray-200 h-full">
    <carga-stock-confirmado
      v-if="mostrarModal"
      @hide="mostrarModal = false"
      :loader="busyGenerarPedido"
      :registroStock="registroStock"
      :key="modalKey"
    />
    <w-top-bar>Punto de venta</w-top-bar>
    <div class="p-5 flex gap-3 relative">
      <div class="p-5 text-sm bg-white rounded-md shadow w-3/5">
        <div class="border-b pb-3 mb-4 relative">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Stock de productos
          </h2>
          <p class="text-sm leading-6 text-gray-600">
            Buscar productos por nombre o SKU, y seleccionar la cantidad a dar
            de alta o baja.
          </p>
          <div class="absolute right-0 top-0">
            <button
              @click="agregarFila"
              class="border-blue-500 border p-1 rounded text-blue-500 hover:text-blue-700 font-medium text-xs mr-2"
            >
              + Agregar
            </button>
          </div>
        </div>

        <table
          v-if="items.length > 0"
          class="min-w-full divide-y divide-gray-200"
        >
          <thead>
            <tr>
              <th
                class="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Producto
              </th>
              <th
                class="bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Cantidad
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td class="py-4 whitespace-nowrap flex items-center gap-3">
                <svg
                  @click="items.splice(index, 1)"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-4 h-4 cursor-pointer hover:text-red-500 zoom-1 z-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
                <select
                  @change="items.push({ producto: '', cantidad: 1, precio: 0 })"
                  v-model="item.producto"
                  class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-20"
                >
                  <option value="">Seleccionar producto</option>
                  <!-- Crear opciones para items en products -->
                  <option
                    v-for="product in productsFiltrados"
                    :key="product._id"
                    :value="product"
                  >
                    {{ product.sku }} - {{ product.name }} ({{
                      product.stock || "Sin Stock"
                    }})
                  </option>
                </select>
              </td>
              <td class="py-4 whitespace-nowrap text-right">
                <!-- Select de 1 a 10 -->
                <select
                  v-if="inputCantidadManual.indexOf(index) === -1"
                  @change="checkMasDe10(index)"
                  v-model="item.cantidad"
                  class="py-2 w-24 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="1">1</option>
                  <option v-for="n in 9" :key="n + 1" :value="n + 1">
                    {{ n + 1 }}
                  </option>
                  <!-- Option para mas -->
                  <option value="11">Más de 10</option>
                </select>
                <input
                  v-if="inputCantidadManual.indexOf(index) > -1"
                  class="py-2 w-24 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  type="number"
                  min="1"
                  v-model="item.cantidad"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center">
          <p class="text-sm leading-6 text-gray-600">
            No hay productos cargados.
          </p>
          <!-- Boton -->
          <button
            @click="agregarFila"
            class="border-blue-500 border p-1 rounded text-blue-500 hover:text-blue-700 font-medium text-xs mt-3"
          >
            Agregar producto
          </button>
        </div>
      </div>

      <div class="w-2/5">
        <FormulateForm
          v-model="nuevoStock"
          @submit="cargarStock"
          #default="{ isLoading, hasErrors }"
        >
          <div class="w-full p-5 text-sm bg-white rounded-md shadow">
            <div class="border-b pb-3 mb-4">
              <h2 class="text-base font-semibold leading-7 text-gray-900">
                Detalles
              </h2>
              <p class="text-sm leading-6 text-gray-600">
                Cargar los detalles de la carga de stock.
              </p>
            </div>
            <div class="flex gap-3">
              <div class="mb-4 w-full">
                <FormulateInput
                  type="select"
                  name="operacion"
                  class="w-100"
                  :options="[
                    { label: 'Carga', value: 'carga' },
                    { label: 'Baja', value: 'baja' },
                  ]"
                  label="¿Qué operación se está realizando?"
                  validation="required"
                  validation-name="operación"
                />
                <FormulateInput
                  type="select"
                  name="encargado"
                  class="w-100"
                  v-if="!vendedorManual"
                  :options="vendedores"
                  @change="vendedorManual = $event.target.value === 'otro'"
                  label="¿Quién es el encargado de la carga?"
                  placeholder="Seleccionar encargado"
                  help="Se puede cambiar en cualquier momento."
                  validation="required"
                  validation-name="encargado"
                />
                <!-- Sino, mostrar un input -->
                <FormulateInput
                  v-else
                  type="text"
                  name="encargado"
                  label="¿Quién es el encargado de la carga?"
                  placeholder="Nombre"
                  validation="required"
                  validation-name="encargado"
                >
                  <template #help>
                    <button
                      @click="vendedorManual = false"
                      class="text-xs text-blue-500 hover:text-blue-700"
                    >
                      Volver al listado
                    </button>
                  </template>
                </FormulateInput>
              </div>
            </div>
            <div class="mb-4">
              <FormulateInput
                type="textarea"
                name="notas"
                label="Notas"
                placeholder="Notas"
                help="Notas para agregar a la carga."
                validation-name="notas"
              />
            </div>
          </div>
          <div class="text-right">
            <FormulateInput
              class="mt-6 w-100 btn-100"
              :class="{
                'bg-blue-500 hover:bg-blue-700 text-white':
                  nuevoStock.operacion === 'carga',
                'bg-red-500 hover:bg-red-700 text-white':
                  nuevoStock.operacion === 'baja',
              }"
              type="submit"
              :disabled="isLoading || hasErrors"
              :label="
                isLoading
                  ? 'Guardando...'
                  : nuevoStock.operacion === 'carga'
                  ? 'Cargar stock'
                  : 'Dar de baja'
              "
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
    
  <script>
import { makeFindMixin } from "feathers-vuex";
import { mapActions } from "vuex";
import CargaStockConfirmado from "@/components/nuevo/CargaStockConfirmado.vue";
export default {
  components: {
    CargaStockConfirmado,
  },
  data() {
    return {
      registroStock: null,
      vendedorManual: false,
      disableClient: false,
      modalKey: 1,
      order: null,
      mostrarModal: false,
      nuevoStock: {},
      tipoDeVentas: [
        {
          label: "Venta en Local Físico",
          value: "venta-local",
        },
        {
          label: "Libreria: Venta",
          value: "libreria-venta",
        },
        {
          label: "Libreria: Consignación",
          value: "libreria-consigancion",
        },
        {
          label: "Evento",
          value: "evento",
        },
      ],
      metodosDePago: [
        {
          label: "Efectivo",
          value: "efectivo",
        },
        {
          label: "Mercado Pago",
          value: "mercadopago",
        },
        {
          label: "Tarjeta",
          value: "tarjeta",
        },
        {
          label: "Cortesia",
          value: "cortesia",
        },
        {
          label: "Otro",
          value: "otro",
        },
      ],
      vendedores: [
        {
          label: "Carito",
          value: "caro",
        },
        {
          label: "Fran",
          value: "fran",
        },
        {
          label: "Pame",
          value: "pame",
        },
        {
          label: "Sofía",
          value: "sofia",
        },
        {
          label: "Walter",
          value: "walter",
        },
        {
          label: "Otro",
          value: "otro",
        },
      ],
      busyBuscarCliente: false,
      busyGenerarPedido: true,
      clienteGuardado: null,
      nuevoCliente: false,
      clienteBuscado: false,
      items: [{ producto: "", cantidad: 1, precio: 0 }],
      descuento: 0,
      sinCliente: false,
      inputCantidadManual: [],
    };
  },
  created() {
    // Obtener vendedor de localstorage
    const encargado = localStorage.getItem("encargado");
    if (encargado) this.nuevoStock.encargado = encargado;
  },
  computed: {
    debug() {
      // si hay un parametro debug en la url, entonces debug = true
      return this.$route.query.debug;
    },
    productsFiltrados() {
      return this.products;
    },
    productsParams() {
      return {
        query: {
          $limit: 300,
          $select: [
            "sku",
            "name",
            "_id",
            "type",
            "price",
            "prelaunch",
            "stock",
            "isPublic",
            "upc",
          ],
          prelaunch: { $ne: true },
          $sort: { sku: 1 },
        },
      };
    },
    calcularTotal() {
      // Total es la suma de cantidades

      let total = this.items.reduce((acc, item) => {
        return acc + item.cantidad;
      }, 0);

      return total;
    },
  },
  mixins: [makeFindMixin({ service: "products" })],
  methods: {
    ...mapActions("impacto-stock", {
      impactarStock: "create",
    }),
    checkMasDe10(index) {
      // Si es mas de 10, abrir un input para que pongan la cantidad
      const cantidad = this.items[index].cantidad;
      if (cantidad > 10) {
        this.inputCantidadManual.push(index);
      } else {
        this.inputCantidadManual = this.inputCantidadManual.filter(
          (i) => i !== index
        );
      }
    },
    setError(error) {
      this.$toast.error(error);
    },
    guardarVendedor() {
      localStorage.setItem("vendedor", this.nuevoStock.vendedor);
    },
    agregarFila() {
      this.items.push({ producto: "", cantidad: 1, precio: 0 });
    },

    async cargarStock() {
      const productosValidos = this.items.filter(
        (item) => item.producto && item.producto._id
      );
      const cantProductosValidos = productosValidos.length;

      if (cantProductosValidos === 0)
        return this.setError("Faltan seleccionar los productos");

      this.busyGenerarPedido = true;

      this.registroStock = {
        operacion: this.nuevoStock.operacion,
        items: productosValidos,
        encargado: this.nuevoStock.encargado,
        notas: this.nuevoStock.notas,
      };

      await this.impactarStock(this.registroStock);

      console.log("this.registroStock", this.registroStock);

      this.modalKey++;
      this.mostrarModal = true;
      this.busyGenerarPedido = false;
    },
  },
};
</script>
    
  <style lang="scss">
.max-w-20 {
  max-width: 20rem;
}
.max-w-10 {
  max-width: 7rem;
}

.btn-100 button {
  width: 100% !important;
}
.btn-100 button {
  justify-content: center;
}
.error-notification {
  min-width: 20rem;
  margin-top: 2rem;
  margin-right: 2rem;
  z-index: 100;
}

.bg-blue-500 button {
  background-color: #3b82f6 !important;
  border: 1px solid #3b82f6 !important;
  border-radius: 0.25rem !important;
}
.bg-blue-500 button:hover {
  background-color: #2563eb !important;
}
.bg-red-500 button {
  background-color: #ef4444 !important;
  border: 1px solid #ef4444 !important;
  border-radius: 0.25rem !important;
}
.bg-red-500 button:hover {
  background-color: #dc2626 !important;
}
</style>
    